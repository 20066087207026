import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Typography, Card, CardHeader, CardContent, CardActionArea } from '@mui/material';
import { Link } from 'react-router-dom';
import * as api from '../../api';

export default function FarmerBlogList() {
  const [posts, setPosts] = useState([]);
  const userDetails = JSON.parse(sessionStorage.getItem('userDetails'));

  useEffect(() => {
    fetchPosts();
  }, []);

  const fetchPosts = async () => {
    try {
      const response = await api.get(`/blog?page=1&limit=100`);
      if (response.data?.data?.blogPosts) {
        setPosts(response.data?.data?.blogPosts);
      }
    } catch (error) {
      console.error('Error fetching posts:', error);
    }
  };

  return (
    <div className="container">
      <Helmet>
        <title> Dashboard | Blog </title>
      </Helmet>

      <h3 className="mb-4">
        Hi, Welcome back {userDetails?.firstName}
      </h3>
      <Typography variant="h5">Blog</Typography>

      {posts.length > 0 && (
        <>
          {posts.map((post) => (
            <Card className="my-4" key={post._id}>
              <CardHeader
                title={post?.title}
                subheader={`Author - ${post?.author?.firstName} ${post?.author?.lastName}`}
              />
              <CardContent dangerouslySetInnerHTML={{ __html: post?.content?.slice(0, 200) }}/> 
              <CardActionArea>
                <Link to={`/farmer/blog/${post._id}`}>Read More</Link>
              </CardActionArea>
            </Card>
          ))}
        </>
      )}
    </div>
  );
}
