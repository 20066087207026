import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Typography, Table, TableHead, TableBody, TableRow, TableCell, Button, Stack, TablePagination } from '@mui/material';
import Swal from 'sweetalert2';
import moment from 'moment';
import * as api from '../../../api';

export default function StateListingPage() {
  const navigate = useNavigate();
  const [state, setState] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    fetchState();
  }, [page, rowsPerPage]);

  const fetchState = async () => {
    try {
      const response = await api.get(`/api/states?page=${page + 1}&limit=${rowsPerPage}`);
      if (response.data?.data) {
        setState(response.data?.data.states);
        setTotalCount(response.data?.data.totalPages); 
      }
    } catch (error) {
      console.error('Error fetching state:', error);
    }
  };

  const handleAddState = () => {
    navigate('/dashboard/add-state');
  };

  const handleEditState = (stateId) => {
    navigate(`/dashboard/edit-state/${stateId}`);
  };

  const handleDeleteState = async (stateId) => {
    try {
      Swal.fire({
        title: 'Are you sure to delete state?',
        showDenyButton: true,
        confirmButtonText: 'Cancel',
        denyButtonText: `Delete`,
      }).then(async(result) => {
        if (result.isDenied) {
          await api.del(`/api/states/${stateId}`);
          Swal.fire('State deleted successfully', '', 'success');
          fetchState();
        }
      });
    } catch (error) {
      console.error('Error deleting state:', error);
    }
  };

  const formatDate = (dateString) => {
    if(dateString) {
      return moment(dateString).format('DD-MM-YYYY');
    } 
      return '-';
  };

  return (
    <Container>
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Typography variant="h4" gutterBottom>
          State Listing
        </Typography>
        <Button variant="contained" onClick={handleAddState}>
          Add State
        </Button>
      </Stack>
      {state?.length === 0 ? (
        <Typography variant="body1">No State available.</Typography>
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Code</TableCell>
              <TableCell>Created</TableCell>
              <TableCell>Updated</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {state?.map((state) => (
              <TableRow key={state._id}>
                <TableCell>{state.name}</TableCell>
                <TableCell>{state.code}</TableCell>
                <TableCell>{formatDate(state.createdAt)}</TableCell>
                <TableCell>{formatDate(state.updatedAt)}</TableCell>
                <TableCell>
                  {/* <Button onClick={() => handleEditState(state._id)}>Edit</Button> */}
                  {/* <Button onClick={() => handleDeleteState(state._id)} color='error'>Delete</Button> */}
                  <button className='btn btn-link' type='button' onClick={() => handleEditState(state._id)}>
                  
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
  <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
  <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
</svg>
                </button>
                <button className='btn btn-link text-danger' type='button' onClick={() => handleDeleteState(state._id)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z"/>
  <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z"/>
</svg>
                </button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      <TablePagination
        component="div"
        count={totalCount} // Use the total count from the state
        page={page}
        onPageChange={(event, newPage) => setPage(newPage)}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={(event) => {
          setRowsPerPage(parseInt(event.target.value, 10));
          setPage(0);
        }}
      />
    </Container>
  );
}
