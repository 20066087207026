import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as api from '../../api';

const initialState = {
  blogPosts: [],
  currentPage: 1,
  postsPerPage: 10,
  totalPosts: 0,
  blogPost: null,
  status: 'idle',
  error: null,
};

export const fetchBlogPosts = createAsyncThunk('blogPosts/fetchBlogPosts', async (page, { getState }) => {
    const { postsPerPage, currentPage } = getState().blogPost;
    console.log("in reducer", postsPerPage, page);
  const response = await api.get(`blog?page=${currentPage}&limit=${postsPerPage}`);
  console.log(response.data?.data?.blogPosts);
  return response.data?.data;
});

export const addBlogPost = createAsyncThunk('blogPosts/addBlogPost', async (newPostData, { rejectWithValue }) => {
  try {
    const response = await api.post( 'api/blog', newPostData);
    return response;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const editBlogPost = createAsyncThunk('blogPosts/editBlogPost', async (postData, { rejectWithValue }) => {
  try {
    const response = await api.put( 'api/blog', postData);
    return response;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const fetchBlogPostById = createAsyncThunk('blogPosts/fetchBlogPostById', async (postId) => {
  const response = await api.get(`blog/${postId}`);
  return response.data?.data;
});

const blogPostSlice = createSlice({
  name: 'blogPosts',
  initialState,
  reducers: {
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setPostsPerPage: (state, action) => {
      state.postsPerPage = action.payload;
    },
    resetPost: (state) => {
      state.blogPost = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBlogPosts.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchBlogPosts.fulfilled, (state, action) => {
        console.log('==',action);
        state.status = 'succeeded';
        state.blogPosts = action.payload.blogPosts;
        state.totalPosts = action.payload.count;
      })
      .addCase(fetchBlogPosts.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      // add blog
      .addCase(addBlogPost.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addBlogPost.fulfilled, (state, action) => {
        console.log('==',action);
        state.status = 'succeeded';
      })
      .addCase(addBlogPost.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      // edit blog
      .addCase(editBlogPost.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(editBlogPost.fulfilled, (state, action) => {
        console.log('==',action);
        state.status = 'succeeded';
      })
      .addCase(editBlogPost.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
    
      .addCase(fetchBlogPostById.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchBlogPostById.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // Update the state with the fetched blog post
        state.blogPost = action.payload;
      })
      .addCase(fetchBlogPostById.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });

  },
});

export const { setCurrentPage, setPostsPerPage,resetPost } = blogPostSlice.actions;

export default blogPostSlice.reducer;
