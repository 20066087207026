import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

// @mui
import {
  Card,
  Stack,
  Button,
  Container,
  Typography,
} from '@mui/material';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import CommonTable from '../../CommonTable';
import * as api from '../../../api';
import Iconify from '../../../components/iconify';

export default function ShopOwners() {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [headers, setHeaders] = useState(['Name', 'Email', 'Mobile', 'Status']);
  const [totalCount, setTotalCount] = useState(0);
  const fetchUsers = async () => {
    setLoading(true);

    try {
      const response = await api.get(`/api/users?role=SHOP_OWNER&page=${page + 1}&size=${rowsPerPage}`); // Replace with your API endpoint
      setTotalCount(response.data?.data?.totalCount)
      const rows = [];
      response.data.data?.data?.forEach(el => {
        rows.push({key: el._id, 'Name': el.firstName + el.lastName, Email: el.email, Mobile:el.mobile, 'Status': el.status})
      });
      setUsers(rows);
      console.log(users);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchUsers();
  }, [page, rowsPerPage]);


  const handleEditUser = (id) => {
    // Handle edit user logic here
    console.log('Edit user:', id);
    navigate(`/dashboard/edit-shop-owner/${id}`);
  };

  const handleDeleteUser = async (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async(result) => {
      if (result.isConfirmed) {
        try {
          await api.del(`/api/users/${id}`);
          console.log('User deleted:', id);
          fetchUsers();
          Swal.fire(
            'Deleted!',
            'User deleted successfully',
            'success'
          )
        } catch (error) {
          toast.error('Error in deleting user');
          console.error('Error deleting user:', error);
        }
        
      }
    })
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const onRowsPerPageChange = (event) => {
    setRowsPerPage(event);
    setPage(0);
  };

  const handleAddUser = () => {
    navigate('/dashboard/add-shop-owner');
  };

  return (
    <>
      <Helmet>
        <title> Shop Owners | NbbFarming </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Shop Owners
          </Typography>
          <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleAddUser}>
            New Shop Owner
          </Button>
        </Stack>

        <Card>
          <CommonTable totalCount={totalCount} headers={headers} data={users} handleChangePage={handleChangePage} onRowsPerPageChange={onRowsPerPageChange} onDelete={handleDeleteUser} onEdit={handleEditUser} rowsPerPage={rowsPerPage} />
          
        </Card>
      </Container>
     
    </>
  );
}
