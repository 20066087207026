import React, { useEffect, useState, useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { Typography, Card, CardHeader, CardContent } from '@mui/material';
import { useParams } from 'react-router-dom';
import * as api from '../../api';

export default function FarmerBlogDetails() {
  const [post, setPost] = useState({});
  const userDetails = JSON.parse(sessionStorage.getItem('userDetails'));
  const { postId } = useParams();

  const fetchPost = useCallback(async () => {
    try {
      const response = await api.get(`/blog/${postId}`);
      if (response.data?.data) {
        setPost(response.data?.data);
      }
    } catch (error) {
      console.error('Error fetching posts:', error);
    }
  }, [postId]);

  useEffect(() => {
    fetchPost();
  }, [fetchPost]);

  return (
    <div className="container">
      <Helmet>
        <title> Dashboard | Blog </title>
      </Helmet>

      <h3 className="mb-4">Hi, Welcome back {userDetails?.firstName}</h3>
      <Typography variant="h5">Blog</Typography>

      {post && (
        <Card className="my-4">
          <CardHeader
            title={post?.title}
            subheader={`Author - ${post?.author?.firstName} ${post?.author?.lastName}`}
          />
          <CardContent dangerouslySetInnerHTML={{ __html: post?.content }} />
        </Card>
      )}
    </div>
  );
}
