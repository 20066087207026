import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import { toast } from 'react-toastify';
import * as api from '../../api';

export default function ViewCropReport() {
  const { userCropId } = useParams();
  const [description, setDescription] = useState('');

  useEffect(() => {
    const fetchCropData = async () => {
      try {
        const response = await api.get(`/api/user-crops/${userCropId}`);
        const cropData = response.data?.data;
        setDescription(cropData.description);
      } catch (error) {
        toast.error(error.response?.data?.message);
        console.error('Error fetching crop data:', error);
      }
    };

    if (userCropId) {
      fetchCropData();
    }
  }, [userCropId]);

  return (
    <div className="container">
      <Typography variant="h4">View Crop Report</Typography>
      <div className="alert alert-light">
        {description ? (
          // eslint-disable-next-line react/no-danger
          <div dangerouslySetInnerHTML={{ __html: description }} />
        ) : (
          <div>
            <h3>Crop report not generated yet.</h3>
          </div>
        )}
      </div>
    </div>
  );
}
