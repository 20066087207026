import { Helmet } from 'react-helmet-async';
import { Container, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import * as api from '../../api';

export default function FarmerCrops() {
  const [crops, setCrops] = useState([]);
  const [userDetails, setUserDetails] = useState(JSON.parse(sessionStorage.getItem('userDetails')));

  useEffect(() => {
    // setUserDetails(JSON.parse(sessionStorage.getItem('userDetails') || '{}'));
    fetchCrops();
  }, []);

  const fetchCrops = async () => {
    try {
      const response = await api.get(`/api/user-crops`);
      if (response.data?.data) {
        setCrops(response.data?.data);
      }
    } catch (error) {
      console.error('Error fetching crops:', error);
    }
  };
  return (
    <>
      <Helmet>
        <title> Dashboard | Minimal UI </title>
      </Helmet>

      <div className='container'>
        <h3 className='mb-4'>
          Hi, Welcome back {userDetails?.firstName}
        </h3>
        <Typography variant='h5'>Registered Crops</Typography>
        {crops.length ? (
         <table className="table">
         <thead>

         <tr>
           <th>Crop Name</th>
           <th>SPC Report</th>
           <th>Crop Report</th>
         </tr>
         </thead>
         <tbody>
           {
             crops.length ? (
               <>
               {crops.map(crop => (
                 <tr key={crop._id}>
                 <td>{crop?.cropId?.name}</td>
                 <td>
                   <Link to={`/farmer/spc-report/${crop._id}`}> {'SPC Report'}</Link>
                   </td>
                   <td>
                   <Link to={`/farmer/crops/${crop._id}`}> {'Crop Report'}</Link>
                   </td>
                   {/* <td>
                   <Link to={`/farmer/crops/${crop._id}`}> {'Crop Report'}</Link>
                   </td> */}
               </tr>
               ))}
               
               </>
             ) : ''
           }
         </tbody>
       </table>
        ) : ''}
      </div>
    </>
  );
}
