import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Stack, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import { razorPayConfig } from '../../config';
import * as api from '../../api';

export default function SignupForm() {
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [mobile, setMobile] = useState('');
  const [crop, setCrop] = useState('');
  const [sowingDate, setSowingDate] = useState('');
  const [cropList, setCropList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [stateId, setStateId] = useState('');
  const [states, setStates] = useState([]);
  const [role, setRole] = useState('');

  const loadScript = async (src) => new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = () => resolve(true);
      script.onerror = () => resolve(false);
      document.body.appendChild(script);
    });

  const displayRazorpay = async () => {
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    try {
      const data = { email, password, firstName, lastName, mobile, cropId: crop, sowingDate, role, stateId };
      const response = await api.post('/signupValidate', data);
      if (response.data.status !== 200) {
        toast.error(response.data.message);
        return;
      }
    } catch (err) {
      toast.error(err.response.data.message);
      console.error('Error in SignUp Validation API', err);
      return;
    }

    const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');

    if (!res) {
      toast.error('Razorpay SDK failed to load. Are you online?');
      return;
    }

    if (role === 'SHOP_OWNER') {
      handleSignup(null);
      return;
    }

    const data = { amount: 180 * 100 };
    const result = await api.post('/initiatePayment', data);

    if (!result) {
      toast.error('Server error. Are you online?');
      return;
    }

    const { amount, id, currency } = result.data;

    const options = {
      key: razorPayConfig.keyId,
      amount: amount.toString(),
      currency,
      name: 'Nbb Farming.',
      description: 'Nbb Farming Service Fee',
      image: 'https://nbbfarming.in/assets/img/logo.png',
      order_id: id,
      handler: async (response) => {
        const data = {
          orderCreationId: id,
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
        };

        const result = await api.post('/paymentSuccess', data);
        if (result && result.status === 200) {
          handleSignup(response);
        } else {
          toast.error('Something went wrong with payment! Please try again later.');
        }
      },
      prefill: {
        name: `${firstName} ${lastName}`,
        email,
        contact: mobile,
      },
      notes: { address: stateId },
      theme: { color: '#61dafb' },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  useEffect(() => {
    fetchCropList();
    fetchStates();
  }, []);

  const fetchStates = async () => {
    setLoading(true);
    try {
      const response = await api.get('/states');
      setStates(response?.data?.data?.states);
    } catch (error) {
      console.error('Error fetching states:', error);
    }
    setLoading(false);
  };

  const fetchCropList = async () => {
    try {
      const response = await api.get('/crops');
      if (response.data?.data) {
        setCropList(response.data?.data);
      }
    } catch (error) {
      console.error('Error fetching crops:', error);
    }
  };

  const handleSignup = async (payment) => {
    setLoading(true);

    try {
      const response = await api.post('/signup', {
        email,
        password,
        firstName,
        lastName,
        mobile,
        cropId: crop,
        sowingDate,
        role,
        stateId,
        payment,
      });

      if (response.data.status === 200) {
        sessionStorage.setItem('token', response.data?.data?.token);
        toast.success('Signup success');
        navigate('/login', { replace: true });
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      toast.error(error.response?.data?.message);
      console.error('Signup error:', error);
    }
    setLoading(false);
  };

  const validateForm = () => {
    const errors = {};

    if (!email) errors.email = 'Email address is required';
    if (!password) errors.password = 'Password is required';
    if (!firstName) errors.firstName = 'First name is required';
    if (!mobile) errors.mobile = 'Mobile is required';

    return errors;
  };

  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="firstName">First name</label>
            <input
              name="firstName"
              label="First Name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
              className="form-control"
            />
            {formErrors.firstName && <div className="text-danger">{formErrors.firstName}</div>}
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="lastName">Last name</label>
            <input
              name="lastName"
              label="Last Name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
              className="form-control"
            />
            {formErrors.lastName && <div className="text-danger">{formErrors.lastName}</div>}
          </div>
        </div>
        <div className="col-md-12 mt-2">
          <div className="form-group">
            <label htmlFor="email">Email: </label>
            <input
              name="email"
              label="Email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="form-control"
            />
            {formErrors.email && <div className="text-danger">{formErrors.email}</div>}
          </div>
        </div>
        <div className="col-md-12 mt-2">
          <div className="form-group">
            <label htmlFor="passsword">Password:</label>
            <input
              name="password"
              label="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="form-control"
            />
            {formErrors.password && <div className="text-danger">{formErrors.password}</div>}
          </div>
        </div>
        <div className="col-md-12 mt-2">
          <div className="form-group">
            <label htmlFor="mobile">Mobile</label>
            <input
              className="form-control"
              name="mobile"
              label="Mobile"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
              required
            />
            {formErrors.mobile && <div className="text-danger">{formErrors.mobile}</div>}
          </div>
        </div>
        <div className="col-md-12 mt-2">
          <div className="form-group">
            <label htmlFor="role">I'm a:</label>
            <select
              value={role}
              onChange={(e) => setRole(e.target.value)}
              id="role"
              name="role"
              className="form-control"
            >
              <option value="">Select</option>
              <option value="FARMER">Farmer</option>
              <option value="SHOP_OWNER">Shop Owner</option>
            </select>
            {formErrors.role && (
              <Typography variant="caption" color="error">
                {formErrors.role}
              </Typography>
            )}
          </div>
        </div>
        <div className="col-md-12 mt-2">
          <div className="form-group">
            <label htmlFor="stateId">State:</label>
            <select
              value={stateId}
              onChange={(e) => setStateId(e.target.value)}
              id="stateId"
              name="stateId"
              className="form-control"
            >
              <option value="">Select</option>
              {states.map((state) => (
                <option key={state._id} value={state._id}>
                  {state.name}
                </option>
              ))}
            </select>
            {formErrors.stateId && <div className="text-danger">{formErrors.stateId}</div>}
          </div>
        </div>

        {role === 'FARMER' ? (
          <>
            <div className="col-md-12 mt-2">
              <div>
                <label>Crop</label>
                <select className="form-control" value={crop} onChange={(e) => setCrop(e.target.value)}>
                  <option value="">Select Crop</option>
                  {cropList.map((cropOption) => (
                    <option key={cropOption.name} value={cropOption._id}>
                      {cropOption.name}
                    </option>
                  ))}
                </select>
                {formErrors.crop && <div className="text-danger">{formErrors.crop}</div>}
              </div>
            </div>
            <div className="col-md-12 mt-2">
              <div className="form-group">
                {/* <InputLabel>Sowing Date</InputLabel> */}
                <label>Sowing Date</label>
                <input
                  className="form-control"
                  placeholder=""
                  name="sowingDate"
                  type="date"
                  value={sowingDate}
                  onChange={(e) => setSowingDate(e.target.value)}
                  required
                />
              </div>
            </div>
          </>
        ) : (
          ''
        )}

        <div className="col-md-12 mt-2">
          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
            <FormControlLabel control={<Checkbox name="terms" />} label="I agree to the Terms & Conditions" />
          </Stack>
        </div>

        <div className="col-md-12 mt-2">
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            onClick={displayRazorpay}
            loading={loading}
          >
            Sign up
          </LoadingButton>
        </div>
      </div>
    </>
  );
}
