import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Box, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import moment from 'moment';
import ReactQuill from 'react-quill';
import * as api from '../../../../api';
import 'react-quill/dist/quill.snow.css';
import './EditSPC.css';

export default function EditSPC() {
  const navigate = useNavigate();
  const { userCropId } = useParams();
  const [stages, setStages] = useState([{ 
    name: '',
    description: '', 
    fieldPreparationDate: '',
    fieldPreparationDescription: '',
    nutrientDate: '',
    nutrientDescription: '',
    weedDate: '',
    weedDescription: '',
    insectDate: '',
    insectDescription: '',
    diseaseDate: '',
    diseaseDescription: '',
    noteDate: '', 
    noteDescription: '',
  }]);

  useEffect(() => {
    if (userCropId) {
      // Fetch the crop data using the userCropId and populate the form fields
      fetchCropData();
    }
  }, [userCropId]);

  const fetchCropData = async () => {
    try {
      const response = await api.get(`/api/user-crops/${userCropId}`); // Replace with your API endpoint to fetch crop data
      const cropData = response.data?.data;
      const finalStages = [];
      cropData.stages.forEach(el => {
        finalStages.push({
          ...el,
        fieldPreparationDate: moment(el.fieldPreparationDate).format('YYYY-MM-DD'),
        nutrientDate: moment(el.nutrientDate).format('YYYY-MM-DD'),
        weedDate: moment(el.weedDate).format('YYYY-MM-DD'),
        insectDate: moment(el.insectDate).format('YYYY-MM-DD'),
        diseaseDate: moment(el.diseaseDate).format('YYYY-MM-DD'),
        noteDate: moment(el.noteDate).format('YYYY-MM-DD'),
        })
      });
      setStages(finalStages);
    } catch (error) {
      toast.error(error.response.data.message);
      console.error('Error fetching crop data:', error);
    }
  };

  const handleStageChange = (index, field, value) => {
    setStages((prevStages) => {
      const updatedStages = [...prevStages];
      updatedStages[index] = { ...updatedStages[index], [field]: value };
      return updatedStages;
    });
  };

  const handleAddStage = () => {
    setStages((prevStages) => [...prevStages, {
      name: '',
    description: '', 
    fieldPreparationDate: '',
    fieldPreparationDescription: '',
    nutrientDate: '',
    nutrientDescription: '',
    weedDate: '',
    weedDescription: '',
    insectDate: '',
    insectDescription: '',
    diseaseDate: '',
    diseaseDescription: '',
    noteDate: '', 
    noteDescription: '',
     }]);
  };

  const handleRemoveStage = (index) => {
    setStages((prevStages) => prevStages.filter((_, i) => i !== index));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const newCrop = {
      stages: stages.map((stage) => ({
        ...stage,
        fieldPreparationDescription: stage.fieldPreparationDescription,
        nutrientDescription: stage.nutrientDescription,
        weedDescription: stage.weedDescription,
        insectDescription: stage.insectDescription,
        diseaseDescription: stage.diseaseDescription,
        noteDescription: stage.noteDescription,
      })),
      _id: userCropId
    };

    try {
      let response;
      if (userCropId) {
        // Send a put request to update the existing crop data
        response = await api.put(`/api/user-crops/${userCropId}`, newCrop); // Replace with your API endpoint to update the crop
      }

      if (response.data.status === 200) {
        setStages([{ 
          name: '',
    description: '', 
    fieldPreparationDate: '',
    fieldPreparationDescription: '',
    nutrientDate: '',
    nutrientDescription: '',
    weedDate: '',
    weedDescription: '',
    insectDate: '',
    insectDescription: '',
    diseaseDate: '',
    diseaseDescription: '',
    noteDate: '', 
    noteDescription: '',
         }]);
        navigate(-1);
        const successMessage = userCropId ? 'Crop updated successfully' : 'Crop added successfully';
        toast.success(successMessage);
      } else {
        // Handle error response
        console.error('Error saving crop:', response.data.message);
      }
    } catch (error) {
      // Handle error
      console.error('Error saving crop:', error);
    }
  };

  return (
    <>
    <div className="container">

    
      <Typography variant="h4">Edit Crop SPC</Typography>
      <form onSubmit={handleSubmit}>
        

        

        <h5>Stages</h5>
        {stages.map((stage, index) => (
          <div key={index} className='row alert alert-light'>
            <div className='col-md-12 form-group' >
              <label htmlFor="name">Stage Name ({index + 1})</label>
              <input
                value={stage.name}
                onChange={(event) => handleStageChange(index, 'name', event.target.value)}
                className='form-control'
                required
                size="small"
              />
            </div>

            <div className='col-md-2 form-group' >
              <label htmlFor="name">Field Preparation Date </label>
              <input
                type="date"
                className='form-control'
                value={stage.fieldPreparationDate}
                onChange={(event) => handleStageChange(index, 'fieldPreparationDate', event.target.value)}
                required
                size="small"
              />
            </div>

            <div  className='col-md-10 form-group' >
              <label htmlFor="description">Field Preparation Description </label>
              {/* <textarea
                value={stage.fieldPreparationDescription}
                onChange={(event) => handleStageChange(index, 'fieldPreparationDescription', event.target.value)}
                className='form-control'
                required
                size="small"
                rows={4}
              /> */}
              <ReactQuill
  value={stage.fieldPreparationDescription}
  onChange={(content) => handleStageChange(index, 'fieldPreparationDescription', content)}
/>
            </div>

            <div className='col-md-2 form-group' >
              <label htmlFor="name">Nutrient Date </label>
              <input
                type="date"
                className='form-control'
                value={stage.nutrientDate}
                onChange={(event) => handleStageChange(index, 'nutrientDate', event.target.value)}
                
                required
                size="small"
              />
            </div>

            <div  className='col-md-10 form-group' >
              <label htmlFor="description">Nutrient Description </label>
              {/* <textarea
                value={stage.nutrientDescription}
                onChange={(event) => handleStageChange(index, 'nutrientDescription', event.target.value)}
                
                className='form-control'
                required
                size="small"
                rows={4}
              /> */}
              <ReactQuill
  value={stage.nutrientDescription}
  onChange={(content) => handleStageChange(index, 'nutrientDescription', content)}
/>
            </div>

            <div className='col-md-2 form-group' >
              <label htmlFor="name">Weed Date </label>
              <input
                type="date"
                className='form-control'
                value={stage.weedDate}
                onChange={(event) => handleStageChange(index, 'weedDate', event.target.value)}
                required
                size="small"
              />
            </div>

            <div  className='col-md-10 form-group' >
              <label htmlFor="description">Weed Description </label>
              {/* <textarea
                value={stage.weedDescription}
                onChange={(event) => handleStageChange(index, 'weedDescription', event.target.value)}
                className='form-control'
                required
                size="small"
                rows={4}
              /> */}
              <ReactQuill
  value={stage.weedDescription}
  onChange={(content) => handleStageChange(index, 'weedDescription', content)}
/>
            </div>

            <div className='col-md-2 form-group' >
              <label htmlFor="name">Insect Date </label>
              <input
                type="date"
                className='form-control'
                value={stage.insectDate}
                onChange={(event) => handleStageChange(index, 'insectDate', event.target.value)}
                required
                size="small"
              />
            </div>

            <div  className='col-md-10 form-group' >
              <label htmlFor="description">Insect Description </label>
              {/* <textarea
                value={stage.insectDescription}
                onChange={(event) => handleStageChange(index, 'insectDescription', event.target.value)}
                className='form-control'
                required
                size="small"
                rows={4}
              /> */}
              <ReactQuill
  value={stage.insectDescription}
  onChange={(content) => handleStageChange(index, 'insectDescription', content)}
/>
            </div>

            <div className='col-md-2 form-group' >
              <label htmlFor="name">Disease Date </label>
              <input
                type="date"
                className='form-control'
                value={stage.diseaseDate}
                onChange={(event) => handleStageChange(index, 'diseaseDate', event.target.value)}
                required
                size="small"
              />
            </div>

            <div  className='col-md-10 form-group' >
              <label htmlFor="description">Disease Description </label>
              {/* <textarea
                value={stage.diseaseDescription}
                onChange={(event) => handleStageChange(index, 'diseaseDescription', event.target.value)}
                className='form-control'
                required
                size="small"
                rows={4}
              /> */}
              <ReactQuill
  value={stage.diseaseDescription}
  onChange={(content) => handleStageChange(index, 'diseaseDescription', content)}
/>
            </div>

            <div className='col-md-2 form-group' >
              <label htmlFor="name">Note Date </label>
              <input
                type="date"
                className='form-control'
                label={`Note Date `}
                value={stage.noteDate}
                onChange={(event) => handleStageChange(index, 'noteDate', event.target.value)}
                
                required
                size="small"
              />
            </div>

            <div  className='col-md-10 form-group' >
              <label htmlFor="description">Note Description </label>
              {/* <textarea
                value={stage.noteDescription}
                onChange={(event) => handleStageChange(index, 'noteDescription', event.target.value)}
                className='form-control'
                required
                size="small"
                rows={4}
              /> */}
               <ReactQuill
  value={stage.noteDescription}
  onChange={(content) => handleStageChange(index, 'noteDescription', content)}
/>
            </div>

            <div className="col-md-3">
              <Button variant="outlined" onClick={() => handleRemoveStage(index)} size="small">
                Remove Stage
              </Button>
            </div>

          </div>
        ))}

        <Button variant="outlined" onClick={handleAddStage} sx={{ my: 2 }} size="small">
          Add Stage
        </Button>

        <br />

        <button type="submit" className='btn btn-dark'>
          {userCropId ? 'Update SPC Report' : 'Add'}
        </button>
      </form>
      </div>
    </>
  );
}
