import { Navigate, useNavigate, useRoutes } from 'react-router-dom';
import { useEffect } from 'react';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
// components
import Page404 from './pages/Page404';
import BlogPage from './pages/BlogPage';
import LoginPage from './pages/LoginPage';
import SignupPage from './pages/SignupPage';
import ProductsPage from './pages/ProductsPage';
import AddStateForm from './pages/Admin/State/StateForm';
import AddFarmerForm from './pages/Admin/Users/AddFarmer';
import FarmersPage from './pages/Admin/Users/FarmersPage';
import CropListingPage from './pages/Admin/Crop/CropList';
import AdminDashboard from './pages/Admin/AdminDashboard';
import StateListingPage from './pages/Admin/State/StateList';
import FarmerDashboard from './pages/Farmer/FarmerDashboard';
import AddCountryForm from './pages/Admin/Country/CountryForm';
import CountryListingPage from './pages/Admin/Country/CountryList';
import ShopOwnerDashboard from './pages/Shop-Owner/ShopOwnerDashboard';
import FarmerCrops from './pages/Farmer/FarmerCrops';
import ShopOwners from './pages/Admin/Users/ShopOwners';
import AddShopOwner from './pages/Admin/Users/AddShopOwner';
import EditCropReport from './pages/Admin/Crop/EditCropReport';
import ViewCropReport from './pages/Farmer/ViewCropReport';
import AddBlog from './pages/Admin/AddBlog';
import AddCrop from './pages/Admin/Crop/AddCrop';
import EditSPC from './pages/Admin/Crop/EditSPC/EditSPC';
import ViewSPCReport from './pages/Farmer/ViewSPCReport';
import ResetPassword from './sections/auth/ResetPassword';
import ForgotPassword from './pages/ForgotPassword';
import FarmerBlogList from './pages/Farmer/FarmerBlogList';
import FarmerBlogDetails from './pages/Farmer/FarmerBlogDetails';
import UserProfile from './pages/Farmer/UserProfile';

function useAuthGuard() {
  const navigate = useNavigate();
  const token = sessionStorage.getItem('token');

  useEffect(() => {
    // Redirect to login if token is not found
    // if (!token && navigate && window.location.pathname !== '/login') {
    //   navigate('/login');
    // }
  }, [navigate, token]);

  return !!token; // Assume the user is authenticated if token is present
}
export default function Router() {
  const isAuthenticated = useAuthGuard();

  const routes = useRoutes([
    {
      path: '/dashboard',
      element: isAuthenticated ? <DashboardLayout /> : <Navigate to="/login" />,
      // element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <AdminDashboard /> },
        
        { path: 'farmers', element: <FarmersPage /> },
        { path: 'add-farmer', element: <AddFarmerForm /> },
        { path: 'edit-farmer/:farmerId', element: <AddFarmerForm /> },

        { path: 'shop-owners', element: <ShopOwners /> },
        { path: 'add-shop-owner', element: <AddShopOwner /> },
        { path: 'edit-shop-owner/:shopOwnerId', element: <AddShopOwner /> },
        
        { path: 'crops', element: <CropListingPage /> },
        { path: 'add-crop', element: <AddCrop /> },
        { path: 'edit-crop/:cropId', element: <AddCrop /> },

        { path: 'crop-report/:userCropId', element: <EditCropReport /> },
        { path: 'spc-report/:userCropId', element: <EditSPC /> },
        
        { path: 'country', element: <CountryListingPage /> },
        { path: 'add-country', element: <AddCountryForm /> },
        { path: 'edit-country/:countryId', element: <AddCountryForm /> },
        
        { path: 'states', element: <StateListingPage/> },
        { path: 'add-state', element: <AddStateForm /> },
        { path: 'edit-state/:stateId', element: <AddStateForm /> },

        { path: 'products', element: <ProductsPage /> },
        { path: 'blog', element: <BlogPage /> },
        { path: 'add-blog', element: <AddBlog /> },
        { path: 'edit-blog/:blogId', element: <AddBlog /> },
        
      ],
    },
    {
      path: '/shop-owner',
      element: isAuthenticated ? <DashboardLayout /> : <Navigate to="/login" />,
      // element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/shop-owner/app" />, index: true },
        { path: 'app', element: <ShopOwnerDashboard /> },
        
        { path: 'farmers', element: <FarmersPage /> },
        { path: 'add-farmer', element: <AddFarmerForm /> },
        { path: 'edit-farmer/:farmerId', element: <AddFarmerForm /> },
        { path: 'blog', element: <BlogPage /> },
      ],
    },
    {
      path: '/farmer',
      element: isAuthenticated ? <DashboardLayout /> : <Navigate to="/login" />,
      // element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/farmer/app" />, index: true },
        { path: 'app', element: <FarmerDashboard /> },      
        { path: 'crops', element: <FarmerCrops/>},
        { path: 'crops/:userCropId', element: <ViewCropReport/>},
        { path: 'spc-report/:userCropId', element: <ViewSPCReport/>},
        { path: 'blog', element: <FarmerBlogList /> },
        { path: 'blog/:postId', element: <FarmerBlogDetails /> },
        { path: 'profile', element: <UserProfile /> },
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: 'forgot-password',
      element: <ForgotPassword/>,
    },
    {
      path: 'reset-password/:token',
      element: <ResetPassword />,
    },
    {
      path: 'signup',
      element: <SignupPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
