import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Container, Typography, Card, CardHeader, CardContent } from '@mui/material';
import {Link} from 'react-router-dom';
import * as api from '../../api';


export default function FarmerDashboard() {
  const [crops, setCrops] = useState([]);

  useEffect(() => {
    fetchCrops();
  }, []);

  const fetchCrops = async () => {
    try {
      const response = await api.get(`/api/user-crops`);
      if (response.data?.data) {
        setCrops(response.data?.data);
      }
    } catch (error) {
      console.error('Error fetching crops:', error);
    }
  };

  return (
    <>
      <Helmet>
        <title> Dashboard | Minimal UI </title>
      </Helmet>

      <div className="container">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back Farmer
        </Typography>

        <Typography variant="h5" sx={{ my: 3 }}>
          Registered Crops
        </Typography>

        <div className="row">
          {crops.length > 0 &&
            crops.map((crop) => (
              <Card key={crop._id} className="alert alert-light col-md-4 col-sm-6 text-center my-3">
                <CardHeader
                  title={
                    <h1 className="text-center">
                      <img
                        style={{ margin: 'auto auto' }}
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAElklEQVR4nO2Ye2hbVRzHjzoFFWRMkaFCGQqif/jPsPfm0dy0a2POmZui7C9BBau5je3SZ9Js2my6dfYc5+MP3QPJw7ZrbLKEONvp2llybjsZPiY6p0wYKmOo+GSzjM3lyLlN1tuuTZfmRm4hX/hCXuR+vvd3fvf+zgWgrLLKKmtJ6PWxR5a/PFI31BarGgJLTXjceTem8Ft/qpo1hETmjUuvgKUiMoEqMIU/EAWxjf0WJodE5g6bMp37JScwurqPPnorVuApDt99+CEVPuemXstkoNd5CzCqAixwLaZomMNz8+WjDcDdFrONAKOKKLAxB8/tyS4frRvCJtYZswrAaNp5xLGCUPhbDn7HmFNtXnkOtw5WHQNGE6Fwh/bsdw3Xzgmfq8LmOKoARlFgTFqGFXRGG8CbkOYNwO2N23YBo4ik0VotPHfzgDVvgOao9SdgFBEKd88IQCFzR+aH527sNZ8HRhFR4OfaAN0fO/PCZ/sgA4wirMA/tQG2HnIsGMAdMTFgBO35bPX1s9d/19CaBQM09pkuAiOIfFR38+wAm1I1Cwbw7LP8DowgxsA1REEXtQF8yfyXUO6OWSMFmXA+SBT0AqEwQSj6DlP0C1bQeUzRBfUGSeExQlGUjEOZpNet0jUEpvDrQgLwO7QvaVunzk4KfJIDz67iwoYniQL9fALQIQDaVchNrHnA+nPP+Po7iILGCwdHM03hP1iBb+ExtHLRAXoofPhqAzSEReZP1TyDKTxdNLwybUzR34SiFn5RWVQfYAq/vLyE8gTwxuxJQtFxPeHJjIqgT/lusOAQRFlrzzWzP2WfewqN2o5iBW0vGbwyXQ2cho8XHAJT1IQpymw+UHNF07YPVg0FjjhWYAWdLfIMs540nGFM5+yNSySNOgoO8eq484ktB2snc/Ab+y2TvqRUrwZMo6evFpTvJwIHa1ln0s7aYzZ1OHy+18zksGmeO7s6X6m/64jb2KZUNdvyYS3bPurYWXCIjritemojL/4ROH7/DZoK9eWD3jbqUIFzDwL0cHPUeqrgAIGAtMwVEs7KQTHjCUnLpwNceeXh5eebn7m2n8W6sdd8YduBmjvBYuQKiYenLpuVjsubHooymjXKuobXqGXXG1zOTrv+RPVji4JXAwRFf/bP3lDP/hhamYN/6VAd8+zLv+EpymGRdSalF0Exkt8x35v9w5P8PZmou50vF77G52tEveB9CenNouA1VfhADgoJ/rotbqoo6VkPTcF791epFddVDRHhPjko/FhKeHfYlPEmpXbd4evfrVwlB4XTamNFTN/wRyp6wzf2mSd9CcmqO/xze1bf5AqKJ9QDBYVR/t4bt7/WoNuSMakPyVrfl24DpZAcFPfyA7mC4ictg+KNuc+9MWmrO2LKFAPv6bf81ZmwP1UScBU+LKxX4UPCr/V7K++a/b0/Zn2gddD6FR+vC2lSz4DljC9uawOllisofJEN8Gy+37WkHPe0xWxvN0et3zf1mc+5I+K/PBS3O2K+1NRvPtfyXtWJjrhtd3uiuvAxedEBQsKIKyTSDYMbrvvfDlpWWWWVBZaC/gOiRvqPjCufpgAAAABJRU5ErkJggg=="
                        alt=""
                      />
                    </h1>
                  }
                />
                <CardContent>
                  <Typography variant="h5" sx={{ my: 4 }}>
                    <b>{crop?.cropId?.name}</b>
                  </Typography>
                  <div>
                    <Link className="btn btn-outline-primary mt-2" to={`/farmer/spc-report/${crop._id}`}>
                      SPC Report
                    </Link>
                    <Link className="btn btn-outline-primary mx-4 mt-2" to={`/farmer/crops/${crop._id}`}>
                      CROP Report
                    </Link>
                  </div>
                </CardContent>
              </Card>
            ))}
        </div>
      </Container>
      </div>
    </>
  );
}
