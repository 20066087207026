import { useState } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { Link, Stack, Checkbox, FormControlLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import * as api from '../../../api';

export default function LoginForm() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    setLoading(true);

    try {
      const response = await api.post('login', {
        username: email,
        password,
      });
      if (response.data.status === 200) {
        sessionStorage.setItem('token', response.data?.data?.token);
        sessionStorage.setItem('userDetails', JSON.stringify(response.data?.data?.dbUser));
        toast.success('Login success');
        if (response.data?.data?.dbUser?.role === 'ADMIN') {
          navigate('/dashboard', { replace: true });
        } else if (response.data?.data?.dbUser?.role === 'SHOP_OWNER') {
          navigate('/shop-owner', { replace: true });
        } else if (response.data?.data?.dbUser?.role === 'FARMER') {
          navigate('/farmer', { replace: true });
        }
      } else {
        toast.error(response.data?.message);
      }
    } catch (error) {
      toast.error(error.response?.data?.message);
      console.error('Login error:', error);
    }

    setLoading(false);
  };

  return (
    <>
      <Stack spacing={3}>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input className='form-control' name="email" label="Email address" value={email} onChange={(e) => setEmail(e.target.value)} />
        </div>

        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input
          type='password'
          className='form-control'
            name="password"
            label="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <FormControlLabel control={<Checkbox name="Remember" />} label="Remember me" />
        </Stack>
        <Link to="/forgot-password" component={RouterLink} variant="subtitle2" underline="hover" style={{ cursor: 'pointer' }}>
          Forgot password?
        </Link>
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick} loading={loading}>
        Login
      </LoadingButton>
    </>
  );
}
