import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TextField, Button, Box, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import * as api from '../../../api';

export default function AddCountryForm() {
  const navigate = useNavigate();
  const { countryId } = useParams();
  const [name, setName] = useState('');
  const [code, setCode] = useState('');
 

  useEffect(() => {     
    if (countryId) {
      // Fetch the country data using the countryId 
      fetchCountryData();
    }
  }, [countryId]);

  const fetchCountryData = async () => {
    try {
      const response = await api.get(`/api/country/${countryId}`); // Replace with your API endpoint to fetch country data
      const countryData = response.data?.data;
      setName(countryData.name);
      setCode(countryData.code);
    } catch (error) {
      console.error('Error fetching country data:', error);
    }
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleCodeChange = (event) => {
    setCode(event.target.value);
  };

 

 

  const handleSubmit = async (event) => {
    event.preventDefault();
    const newCountry = {
      name,
      code
      
    };
    try {
      let response;
      if (countryId) {
        // Send a put request to update the existing country data
        newCountry._id = countryId;
        response = await api.put(`/api/country`, newCountry); // Replace with your API endpoint to update the country    
       
      } else {
        // Send a post request to add a new country
        response = await api.post('/api/country', newCountry); // Replace with your API endpoint to save the country
        
      }
      
      if (response.data.status === 200) {
        setName('');
        setCode('');
        
        navigate('/dashboard/country');
        const successMessage = countryId ? 'Country updated successfully' : 'Country added successfully';
        toast.success(successMessage);
      }
     else {
        // Handle error response
        console.error('Error saving country:', response.data.message);
      }
    } catch (error) {
      // Handle error
      toast.error(error.response.data.message);
     console.log(error.response.data.message)
      console.error('Error saving country:', error);
    }
  };

  return (
    <>
      <Typography variant="h4">Add Country</Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Country Name"
          value={name}
          onChange={handleNameChange}
          fullWidth
          required
          size="small"
          sx={{ my: 2 }}
        />

        <TextField
          label="Country Code"
          value={code}
          onChange={handleCodeChange}
          fullWidth
          required
          size="small"
          sx={{ my: 2 }}
        />
        <br />

        <Button type="submit" variant="contained" size="small">
          {countryId ? 'Update Country' : 'Add Country'}
        </Button>
      </form>
    </>
  );
}
