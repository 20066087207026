import axios from 'axios';
import { baseURL } from '../config';

const api = axios.create({
  baseURL
});

// Add a request interceptor to attach the token to the request headers
api.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem('token'); // Retrieve the token from wherever you store it
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)  
);

api.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
  // {
    // if (error.response && error.response.status === 401) {
    //   console.log('redirect');
    //   history.push('/login', { replace: true });
    //   window.location.reload();
    // }
  // }
);

// Custom wrapper functions for GET, POST, PUT, and DELETE methods
export const get = (url, config) => api.get(url, config);
export const post = (url, data, config) => api.post(url, data, config);
export const put = (url, data, config) => api.put(url, data, config);
export const del = (url, config) => api.delete(url, config);

export default api;
