import { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TextField, Button, Box, Typography } from '@mui/material';
import { Editor } from '@tinymce/tinymce-react';
import { toast } from 'react-toastify';
import * as api from '../../../api';

export default function EditCropReport() {
  const navigate = useNavigate();
  const { userCropId } = useParams();
  const [description, setDescription] = useState('');
  const editorRef = useRef(null);
  useEffect(() => {
    if (userCropId) {
      fetchCropData();
    }
  }, [userCropId]);

  const fetchCropData = async () => {
    try {
      const response = await api.get(`/api/user-crops/${userCropId}`);
      const cropData = response.data?.data;
      setDescription(cropData.description);
      // console.log(response.data?.data.description);
      // editorRef?.current?.setContent(response.data?.data.description);
    } catch (error) {
      toast.error(error.response?.data?.message);
      console.error('Error fetching crop data:', error);
    }
  };

  const submit = async () => {
    if (editorRef.current) {
      setDescription(editorRef.current.getContent());
    }
    const newCrop = {
      _id: userCropId,
      description: editorRef.current.getContent(),
    };

    try {
      let response;
      if (userCropId) {
        response = await api.put(`/api/user-crops/${userCropId}`, newCrop); // Replace with your API endpoint to update the crop
      } else {
        response = await api.post('/api/user-crops', newCrop);
      }

      if (response.data.status === 200) {
        setDescription('');
        navigate(-1);
        const successMessage = userCropId ? 'Crop report updated successfully' : 'Crop report added successfully';
        toast.success(successMessage);
      } else {
        console.error('Error saving crop:', response.data.message);
      }
    } catch (error) {
      console.error('Error saving crop:', error);
    }
  };

  // const handleChange = () => {
  //   setDescription(editorRef.current.getContent())
  // }
  return (
    <>
    <div className="container">
      <Typography variant="h4">Edit Crop Report</Typography>
      
      <Editor
        initialValue={description}
        onChange={(e) => setDescription(e.target.value)}
        apiKey='znclnvt4x9ddccsw1xtl8ajjig8zvkx6bntl5cesj11tod6f'
        onInit={(evt, editor) => {editorRef.current = editor}}
        init={{
          height: 500,
          menubar: false,
          plugins: [
            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
            'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
          ],
          toolbar: 'undo redo | blocks | ' +
            'bold italic forecolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat | help',
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
        }}
      />
       {/* <div dangerouslySetInnerHTML={{ __html: description }} /> */}
        <Button className='mt-2' onClick={submit} type="submit" variant="contained" >
          {userCropId ? 'Update Crop Report' : 'Add Crop Report'}
        </Button>
        </div>
    </>
  );
}
