import { useState } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { Stack, TextField, Button, Typography, Container, Link } from '@mui/material';
import { toast } from 'react-toastify';
import { styled } from '@mui/material/styles';
import { Helmet } from 'react-helmet-async';
import * as api from '../../api';
import Logo from '../../components/logo';
import useResponsive from '../../hooks/useResponsive';

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

export default function ResetPassword() {
  // const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const { token } = useParams();
  const navigate = useNavigate();

  const handleResetPassword = async () => {
    setLoading(true);
    try {
      const response = await api.post('reset-password', {
        token,
        password,
      });

      // Handle the response from the server
      if (response.data.status === 200) {
        toast.success('Password reset successfully');
        navigate('/login');
      } else {
        toast.error('Failed to send password reset link');
      }
    } catch (error) {
      toast.error('Failed to send password reset link');
      console.error('Password reset error:', error);
    }

    setLoading(false);
  };

  const mdUp = useResponsive('up', 'md');
  return (
    <>
      <Helmet>
        <title> Forgot Password | NbbFarming </title>
      </Helmet>

      <StyledRoot>
        <Logo
          sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        />

        {mdUp && (
          <StyledSection>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Hi, Welcome Back
            </Typography>
            <img src="/assets/illustrations/illustration_login.png" alt="login" />
          </StyledSection>
        )}

        <Container maxWidth="sm">
          <StyledContent>
            
            <Stack spacing={3} sx={{ maxWidth: 800, margin: 'auto' }}>
              <Typography variant="h4">Reset Password</Typography>
              <TextField
                label="New password"
                type="password"
                fullWidth
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <TextField
                label="Confirm New password"
                type="password"
                fullWidth
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <Button fullWidth variant="contained" color="primary" onClick={handleResetPassword} disabled={loading}>
                Reset Password
              </Button>
            </Stack>
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
