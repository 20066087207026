import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, Button, TextField, Stack } from '@mui/material';
import { toast } from 'react-toastify';
import { useState } from 'react';
import useResponsive from '../hooks/useResponsive';
import * as api from '../api';
import Logo from '../components/logo';

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const handleResetPassword = async () => {
    setLoading(true);

    try {
      // Send a request to the server to reset the password
      const response = await api.post('forgot-password', {
        email,
      });

      // Handle the response from the server
      if (response.data.status === 200) {
        toast.success('Password reset link sent successfully');
      } else {
        toast.error('Failed to send password reset link');
      }
    } catch (error) {
      toast.error('Failed to send password reset link');
      console.error('Password reset error:', error);
    }

    setLoading(false);
  };

  const mdUp = useResponsive('up', 'md');

  return (
    <>
      <Helmet>
        <title> Forgot Password | NbbFarming </title>
      </Helmet>

      <StyledRoot>
        <Logo
          sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        />

        {mdUp && (
          <StyledSection>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Hi, Welcome Back
            </Typography>
            <img src="/assets/illustrations/illustration_login.png" alt="login" />
          </StyledSection>
        )}

        <Container maxWidth="sm">
          <StyledContent>
            
            
            <Stack spacing={3} sx={{ maxWidth: 400, margin: 'auto' }}>
      <Typography variant="h4">Forgot Password</Typography>
      <Typography variant="body2" color="text.secondary">
        Enter your email address, and we will send you a password reset link.
      </Typography>
      <TextField
        label="Email address"
        type="email"
        fullWidth
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <Button
        fullWidth
        variant="contained"
        color="primary"
        onClick={handleResetPassword}
        disabled={loading}
      >
        Reset Password
      </Button>
      <Typography variant="body2" color="text.secondary">
        Remember your password? <RouterLink to="/login">Login</RouterLink>
      </Typography>
    </Stack>

          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
