import { Button, Container, Stack, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import moment from 'moment';

import { fetchBlogPosts, setCurrentPage, setPostsPerPage } from '../redux/slices/blogPostSlice';
import Iconify from '../components/iconify';
import CommonTable from './CommonTable';
import * as api from '../api';

export default function BlogPage() {
  const blogPosts = useSelector((state) => state.blogPost.blogPosts) || [];
  const totalCount = useSelector((state) => state.blogPost.totalPosts) || 0;
  const rowsPerPage = useSelector((state) => state.blogPost.postsPerPage) || 10;
  const page = useSelector((state) => state.blogPost.page) || 1;
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [headers] = useState(['Title', 'Author', 'Created', 'Updated']);

  useEffect(() => {
    dispatch(fetchBlogPosts(page));
  }, []);
  
  useEffect(() => {
    const rows = [];
      blogPosts.forEach(el => {
        rows.push({key: el._id, 'Title': el.title, Author: el.author?.firstName, 'Created':formatDate(el.createdAt), 'Updated':formatDate(el.updatedAt)})
      });
      setData(rows);
  }, [blogPosts]);
  
  
  const handleEditUser = (id) => {
    console.log('Edit user:', id);
    navigate(`/dashboard/edit-blog/${id}`);
  };

  const handleDeleteUser = async (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async(result) => {
      if (result.isConfirmed) {
        try {
          await api.del(`/api/blog/${id}`);
          console.log('Post deleted:', id);
          Swal.fire(
            'Deleted!',
            'User deleted successfully',
            'success'
          );
          dispatch(fetchBlogPosts(page));
        } catch (error) {
          toast.error('Error in deleting user');
          console.error('Error deleting user:', error);
        }
        
      }
    })
  };

  const handleChangePage = (newPage) => {
    console.log(newPage);
    dispatch(setCurrentPage(newPage + 1));
    dispatch(fetchBlogPosts(newPage));
  };

  const onRowsPerPageChange = (event) => {
    dispatch(setPostsPerPage(event));
    dispatch(setCurrentPage(1));
    dispatch(fetchBlogPosts());
  };

  const formatDate = (dateString) => {
    if(dateString) {
      return moment(dateString).format('DD-MM-YYYY hh:mm a');
    } 
      return '-';
  };

  return (
    <>
      <Helmet>
        <title> Dashboard: Blog | Minimal UI </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Blog
          </Typography>
          <Button onClick={() => navigate('/dashboard/add-blog')} variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
            New Post
          </Button>
        </Stack>

        {blogPosts.length &&
        <>

        {/* <Grid container spacing={3}>
          {blogPosts.map((post, index) => (
            <BlogPostCard key={post._id} post={post} index={index} />
          ))}
        </Grid> */}
        <CommonTable totalCount={totalCount} headers={headers} data={data} handleChangePage={handleChangePage} onRowsPerPageChange={onRowsPerPageChange} onDelete={handleDeleteUser} onEdit={handleEditUser} rowsPerPage={rowsPerPage} />
          
        </>
        }
      </Container>
    </>
  );
}
