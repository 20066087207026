import { Container, Typography, Table, TableHead, TableBody, TableRow, TableCell, Button, Stack, TablePagination } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import * as api from '../../../api';

export default function CountryListingPage() {
  const navigate = useNavigate();
  const [country, setCountry] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    fetchCountry();
  }, [page, rowsPerPage]);

  const fetchCountry = async () => {
    try {
      const response = await api.get(`/api/country?page=${page + 1}&limit=${rowsPerPage}`);
      if (response.data?.data) {
        setCountry(response.data.data.data);
        setTotalCount(response.data.data.total); 
      }
    } catch (error) {
      console.error('Error fetching country:', error);
    }
  };

  const handleAddCountry = () => {
    navigate('/dashboard/add-country');
  };

  const handleEditCountry = (countryId) => {
    navigate(`/dashboard/edit-country/${countryId}`);
  };

  const handleDeleteCountry = async (countryId) => {
    try {
      Swal.fire({
        title: 'Are you sure to delete country?',
        showDenyButton: true,
        confirmButtonText: 'Cancel',
        denyButtonText: `Delete`,
      }).then(async(result) => {
        if (result.isDenied) {
          await api.del(`/api/country/${countryId}`);
          Swal.fire('Country deleted successfully', '', 'success');
          fetchCountry();
        }
      });
    } catch (error) {
      console.error('Error deleting country:', error);
    }
  };

  return (
    <Container>
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Typography variant="h4" gutterBottom>
          Country Listing
        </Typography>
        <Button variant="contained" onClick={handleAddCountry}>
          Add Country
        </Button>
      </Stack>
      {country.length === 0 ? (
        <Typography variant="body1">No Country available.</Typography>
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Code</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {country.map((country) => (
              <TableRow key={country._id}>
                <TableCell>{country.name}</TableCell>
                <TableCell>{country.code}</TableCell>
                <TableCell>
                  <Button onClick={() => handleEditCountry(country._id)}>Edit</Button>
                  <Button onClick={() => handleDeleteCountry(country._id)} color='error'>Delete</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      <TablePagination
        component="div"
        count={totalCount} // Use the total count from the state
        page={page}
        onPageChange={(event, newPage) => setPage(newPage)}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={(event) => {
          setRowsPerPage(parseInt(event.target.value, 10));
          setPage(0);
        }}
      />
    </Container>
  );
}
