import { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { razorPayConfig } from '../../../config';
import * as api from '../../../api';

export default function AddFarmerForm() {
  const navigate = useNavigate();
  const { farmerId } = useParams();
  const [crops, setCrops] = useState([]);
  const [userCrops, setUserCrops] = useState([]);
  const [states, setStates] = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    setValue,
  } = useForm();

  useEffect(() => {
    const fetchCropsAndFarmerData = async () => {
      await fetchCropsList();
      if (farmerId) {
        // Fetch the farmer data using the farmerId and populate the form fields
        await fetchFarmerData();
      }
    };

    fetchCropsAndFarmerData();
    fetchUserCrops();
    fetchStates();
  }, [farmerId]); // Add farmerId as a dependency

  const fetchFarmerData = async () => {
    try {
      const response = await api.get(`/api/users/${farmerId}`);
      const farmerData = response.data?.data;
      // Use setValue to set form field values instead of useState
      setValue('firstName', farmerData.firstName);
      setValue('lastName', farmerData.lastName);
      setValue('email', farmerData.email);
      setValue('mobile', farmerData.mobile);
      setValue('stateId', farmerData.stateId);
      setValue('shopOwnerId', farmerData.shopOwnerId);
      setValue('status', farmerData.status);
      setValue('cropId', farmerData.cropId);
      setValue('farmerCode', farmerData.farmerCode);
    } catch (error) {
      console.error('Error fetching farmer data:', error);
    }
  };

  const fetchCropsList = async () => {
    try {
      const response = await api.get(`/api/crops`);
      const cropsData = response.data?.data.crops;
      if (Array.isArray(cropsData)) {
        setCrops(cropsData);
      } else {
        console.error('Invalid crops data received from API:', cropsData);
      }
    } catch (error) {
      console.error('Error fetching crops data:', error);
    }
  };

  const fetchUserCrops = async () => {
    try {
      const response = await api.get(`/api/userCropsByUser/${farmerId}`);
      const cropsData = response.data?.data;
      if (Array.isArray(cropsData)) {
        setUserCrops(cropsData);
      } else {
        console.error('Invalid crops data received from API:', cropsData);
      }
    } catch (error) {
      console.error('Error fetching crops data:', error);
    }
  };


  const onSubmit = async (data) => {
    console.log(data)
    
    try {
      let response;
      if (farmerId) {
        // Send a put request to update the existing farmer data
        data._id = farmerId;
        response = await api.put(`/api/users`, data);
      } else {
        // Send a post request to add a new farmer
        data.role = "FARMER";
        response = await api.post('/api/users/addFarmer', data);
      }
      if (response.data.status === 200) {
        navigate('/dashboard/farmers');
        const successMessage = farmerId ? 'Farmer updated successfully' : 'Farmer added successfully';
        toast(successMessage);
      } else {
        // Handle error response
        toast(response.data?.message);
        console.error('Error saving farmer:', response.data.message);
      }
    } catch (error) {
      // Handle error
      toast(error.response?.data?.message);
      console.error('Error saving farmer:', error);
    }
  };

  const fetchStates = async () => {
    try {
      const response = await api.get(`/states`); // Replace with your API endpoint
      setStates(response?.data?.data?.states);
      console.log(states);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const loadScript = async (src) => new Promise((resolve) => {
    const script = document.createElement('script');
    script.src = src;
    script.onload = () => resolve(true);
    script.onerror = () => resolve(false);
    document.body.appendChild(script);
  });

  const displayRazorpay = async (data) => {
    

    try {
      const data2 = {
        email:data.email,
        password:data.password,
        firstName:data.firstName,
        lastName:data.lastName,
        mobile:data.mobile,
        cropId:data.cropId,
        sowingDate:data.sowingDate,
        role:data.role,
        stateId:data.stateId
      };
      const response = await api.post('/signupValidate', data2);
      if (response.data.status !== 200) {
        toast.error(response.data.message);
        return;
      }
    } catch (err) {
      toast.error(err.response.data.message);
      console.error('Error in SignUp Validation API', err);
      return;
    }

    const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');

    if (!res) {
      toast.error('Razorpay SDK failed to load. Are you online?');
      return;
    }

    const data1 = { amount: 180 * 100 };
    const result = await api.post('/initiatePayment', data1);

    if (!result) {
      toast.error('Server error. Are you online?');
      return;
    }

    const { amount, id, currency } = result.data;

    const options = {
      key: razorPayConfig.keyId,
      amount: amount.toString(),
      currency,
      name: 'Nbb Farming.',
      description: 'Nbb Farming Service Fee',
      image: 'https://nbbfarming.in/assets/img/logo.png',
      order_id: id,
      handler: async (response) => {
        const data3 = {
          orderCreationId: id,
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
        };

        const result = await api.post('/paymentSuccess', data3);
        console.log('result=', result);
        if (result && result.status === 200) {
          data.payment = response;
          onSubmit(data);
        } else {
          toast.error('Something went wrong with payment! Please try again later.');
        }
      },
      prefill: {
        name: `${data.firstName} ${data.lastName}`,
        email: data.email,
        contact: data.mobile,
      },
      notes: { address: data.stateId },
      theme: { color: '#61dafb' },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  return (
    <>
    <div className="container">
      <h4>Add Farmer</h4>
      <form onSubmit={handleSubmit(displayRazorpay)} className="form">
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="firstName">First Name:</label>
              <input
                id="firstName"
                name="firstName"
                type="text"
                {...register('firstName', { required: 'First name is required' })}
                className="form-control"
              />
              {isDirty && errors.firstName && <span style={{ color: 'red' }}>{errors.firstName.message}</span>}
            </div>

            <div className="form-group">
              <label htmlFor="lastName">Last Name:</label>
              <input
                id="lastName"
                name="lastName"
                type="text"
                {...register('lastName', { required: 'Last name is required' })}
                className="form-control"
              />
              {isDirty && errors.lastName && <span style={{ color: 'red' }}>{errors.lastName.message}</span>}
            </div>

            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input
                id="email"
                name="email"
                type="email"
                {...register('email', {
                  required: 'Email is required',
                  pattern: {
                    value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/i,
                    message: 'Invalid email format',
                  },
                })}
                className="form-control"
              />
              {isDirty && errors.email && <span style={{ color: 'red' }}>{errors.email.message}</span>}
            </div>

            <div className="form-group">
              <label htmlFor="mobile">Mobile:</label>
              <input
                id="mobile"
                name="mobile"
                type="tel"
                {...register('mobile', {
                  required: 'Mobile number is required',
                  pattern: {
                    value: /^\d{10}$/,
                    message: 'Mobile number must be 10 digits',
                  },
                })}
                className="form-control"
              />
              {isDirty && errors.mobile && <span style={{ color: 'red' }}>{errors.mobile.message}</span>}
            </div>
          </div>
          <div className="col-md-6">
            

            <div className="form-group">
              <label htmlFor="farmerCode">Farmer Code:</label>
              <input
                id="farmerCode"
                name="farmerCode"
                type="text"
                className="form-control"
                {...register('farmerCode', { required: 'Farmer code is required' })}
              />
              {isDirty && errors.farmerCode && <span style={{ color: 'red' }}>{errors.farmerCode.message}</span>}
            </div>

            {states && states.length > 0 ? (
              <div className="form-group">
                <label htmlFor="stateId">State:</label>
                <select
                  id="stateId"
                  name="stateId"
                  {...register('stateId', { required: 'State is required' })}
                  className="form-control"
                >
                  <option value="">Select</option>
                  {states.map((village) => (
                    <option key={village._id} value={village._id}>
                      {village.name}
                    </option>
                  ))}
                </select>
                {isDirty && errors.stateId && <span style={{ color: 'red' }}>{errors.stateId.message}</span>}
              </div>
            ) : (
              <div>Loading states...</div>
            )}

              {!farmerId &&
                  <>
                  {crops && crops.length > 0 ? (
              <>
              <div className="form-group">
                <label htmlFor="cropId">Crop:</label>
                <select
                  id="cropId"
                  name="cropId"
                  {...register('cropId', { required: 'State is required' })}
                  className="form-control"
                >
                  <option value="">Select</option>
                  {crops.map((crop) => (
                    <option key={crop._id} value={crop._id}>
                      {crop.name}
                    </option>
                  ))}
                </select>
                {isDirty && errors.cropId && <span style={{ color: 'red' }}>{errors.cropId.message}</span>}
              </div>
              <div className="form-group">
            {/* <InputLabel>Sowing Date</InputLabel> */}
            <label>Sowing Date</label>
            <input
              className="form-control"
              placeholder=""
              name="sowingDate"
              type="date"
              required
              {...register('sowingDate', { required: 'Sowing Date is required' })}
            />
          </div>
              </>
            ) : (
              <div>Loading crops...</div>
            )}
                  </>
              }

            <br />
          </div>
        </div>

        <div className="mt-2">
          <button type="submit" className="btn btn-dark">
            {farmerId ? 'Update Farmer' : 'Add Farmer'}
          </button>
        </div>
      </form>

      <h4 className='mt-4'>Registered Crops</h4>
      <table className="table">
        <thead>
          <tr>
            <th>Crop Name</th>
            <th>SPC Report</th>
            <th>Crop report</th>
          </tr>
        </thead>
        <tbody>
          {userCrops.length &&
            userCrops.map((crop) => (
              <tr key={crop._id}>
                <td>{crop?.cropId?.name}</td>
                <td>
                  <Link to={`/dashboard/spc-report/${crop._id}`}> {'SPC Report'}</Link>
                </td>
                <td>
                  <Link to={`/dashboard/crop-report/${crop._id}`}> {'Report'}</Link>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      </div>
    </>
  );
}
