import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TextField, Button, Typography, Select, MenuItem } from '@mui/material';
import { toast } from 'react-toastify';
import * as api from '../../../api';

export default function AddStateForm() {
  const navigate = useNavigate();
  const { stateId } = useParams();
  const [name, setName] = useState('');
  const [code, setCode] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [countries, setCountries] = useState([]); // Initialize countries as an empty array

  useEffect(() => {
    if (stateId) {
      // Fetch the state data using the stateId
      fetchStateData();
    }
    // Fetch the list of countries when the component mounts
    fetchCountries();
  }, []);

  const fetchStateData = async () => {
    try {
      const response = await api.get(`/api/states/${stateId}`);
      const stateData = response.data?.data;
      setName(stateData.name);
      setCode(stateData.code);
      setSelectedCountry(stateData.countryId);
    } catch (error) {
      console.error('Error fetching state data:', error);
    }
  };

  const fetchCountries = async () => {
    try {
      const response = await api.get('/countries'); // Replace with your API endpoint to fetch countries
      const countryData = response.data?.data?.data;
      setCountries(countryData);
    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleCodeChange = (event) => {
    setCode(event.target.value);
  };

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const newState = {
      name,
      code,
      countryId: selectedCountry,
    };
    try {
      let response;
      if (stateId) {
        // Send a put request to update the existing state data
        newState._id = stateId;
        response = await api.put(`/api/states`, newState);
      } else {
        // Send a post request to add a new state
        response = await api.post('/api/states', newState);
      }

      if (response.data.status === 200) {
        setName('');
        setCode('');
        setSelectedCountry('');
        navigate('/dashboard/states');
        const successMessage = stateId ? 'State updated successfully' : 'State added successfully';
        toast.success(successMessage);
      } else {
        // Handle error response
        console.error('Error saving state:', response.data.message);
      }
    } catch (error) {
      // Handle error
      toast.error(error.response?.data?.message || 'Error saving state');
      console.error('Error saving state:', error);
    }
  };

  return (
    <>
      <div className="container">
      <Typography variant="h4">Add State</Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="State Name"
          value={name}
          onChange={handleNameChange}
          fullWidth
          required
          size="small"
          sx={{ my: 2 }}
        />

        <TextField
          label="State Code"
          value={code}
          onChange={handleCodeChange}
          fullWidth
          required
          size="small"
          sx={{ my: 2 }}
        />

        <Select
          label="Country"
          value={selectedCountry}
          onChange={handleCountryChange}
          fullWidth
          required
          size="small"
          sx={{ my: 2 }}
        >
          {countries.map((country) => (
            <MenuItem key={country._id} value={country._id}>
              {country.name}
            </MenuItem>
          ))}
        </Select>

        <Button type="submit" variant="contained" size="small">
          {stateId ? 'Update State' : 'Add State'}
        </Button>
      </form>
      </div>
    </>
  );
}
