import React, { useEffect, useState } from 'react';
import { Typography, Paper, Grid, Avatar, Button } from '@mui/material';

const UserProfile = () => {
  // Assume user details are fetched or provided from a parent component or context
  const [user, setUserDetails] = useState({});

  useEffect(() => {
    setUserDetails(JSON.parse(sessionStorage.getItem('userDetails') || '{}'));
  }, []);

  return (
    <div className='container'>
      <h3>User Details</h3>
      <Paper elevation={3} style={{ padding: '20px', margin: '20px' }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <Avatar alt="User Avatar" src={user?.avatarUrl} />
        </Grid>
        <Grid item>
          <Typography variant="h5">{`${user?.firstName} ${user?.lastName}`}</Typography>
          <Typography variant="subtitle1" color="textSecondary">{`Email: ${user?.email}`}</Typography>
        </Grid>
      </Grid>
      {/* <div style={{ marginTop: '20px' }}>
        <Typography variant="body1" color="textSecondary">{`Username: ${user?.username}`}</Typography>
        <Typography variant="body1" color="textSecondary">{`Bio: ${user?.bio || 'No bio available'}`}</Typography>
      </div>
      <div style={{ marginTop: '20px' }}>
        <Button variant="contained" color="primary">
          Edit Profile
        </Button>
      </div> */}
    </Paper>
    </div>
  );
};

export default UserProfile;
