import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createBrowserHistory } from 'history';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';
import 'bootstrap/dist/css/bootstrap.min.css';
// ----------------------------------------------------------------------

const history = createBrowserHistory();
export default function App() {
  return (
    <HelmetProvider>
      <BrowserRouter history={history}>
        <ThemeProvider>
          <ScrollToTop />
          <StyledChart />
          <Router />
          <ToastContainer /> {/* Add ToastContainer component */}
        </ThemeProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
}
