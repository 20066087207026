import { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {  Typography } from '@mui/material';
import { toast } from 'react-toastify';
import moment from 'moment';
import * as api from '../../api';

export default function ViewSPCReport() {
  const navigate = useNavigate();
  const { userCropId } = useParams();
  const [stages, setStages] = useState([]);
  useEffect(() => {
    if (userCropId) {
      fetchCropData();
    }
  }, []);

  const fetchCropData = async () => {
    try {
      const response = await api.get(`/api/user-crops/${userCropId}`);
      const cropData = response.data?.data;
      setStages(cropData.stages);
    } catch (error) {
      toast.error(error.response?.data?.message);
      console.error('Error fetching crop data:', error);
    }
  };

  return (
    <>
    <div className="container">

      <Typography variant="h4">View Crop SPC Report</Typography>
      {
  stages.map((stage, index) =>
    moment(stage?.fieldPreparationDate).isBefore(moment().add(7, 'days')) && (
      <div className="alert alert-light" key={index}>
        <h4>Stage {index + 1}: {stage?.name}</h4>
        <hr />
        <h5 className='text-dark'>Field Preparation {moment(stage?.fieldPreparationDate).format('DD-MMM-YYYY')}</h5>
        <hr />
        <p dangerouslySetInnerHTML={{ __html: stage?.fieldPreparationDescription }}  />

        <hr />
        <h5 className='text-dark'>Nutrient {moment(stage?.nutrientDate).format('DD-MMM-YYYY')}</h5>
        <hr />
        <p dangerouslySetInnerHTML={{ __html: stage?.nutrientDescription }}  />
        
        <hr />
        <h5 className='text-dark'>Weed {moment(stage?.weedDate).format('DD-MMM-YYYY')}</h5>
        <hr />
        <p dangerouslySetInnerHTML={{ __html: stage?.weedDescription }}  />
        
        <hr />
        <h5 className='text-dark'>Insect/Paste {moment(stage?.insectDate).format('DD-MMM-YYYY')}</h5>
        <hr />
        <p dangerouslySetInnerHTML={{ __html: stage?.insectDescription }}  />
        
        <hr />
        <h5 className='text-dark'>Disease {moment(stage?.diseaseDate).format('DD-MMM-YYYY')}</h5>
        <hr />
        <p dangerouslySetInnerHTML={{ __html: stage?.diseaseDescription }}  />
        
        <hr />
        <h5>Note {moment(stage?.noteDate).format('DD-MMM-YYYY')}</h5>
        <hr />
        <p dangerouslySetInnerHTML={{ __html: stage?.noteDescription }}  />
      </div>
    )
  )
}

    
    </div>
    </>
  );
}
