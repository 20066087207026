import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { TextField, Button, Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Editor } from '@tinymce/tinymce-react';
import { addBlogPost, editBlogPost, fetchBlogPostById, resetPost } from '../../redux/slices/blogPostSlice';

function AddBlog() {
  const { register, handleSubmit, reset } = useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {blogId} = useParams();
  const post = useSelector((state) => state.blogPost.blogPost) || null;
  const editorRef = useRef(null);
  const [editorContent, setEditorContent] = useState(post ? post.content : 'Hello');

  const handleFormSubmit = (data) => {
    // e.preventDefault();
    console.log('blogId=', blogId);
  console.log('handlesubmit', data);
  data.content = editorRef.current.getContent();
  if (blogId) {
    // Edit an existing post
    dispatch(editBlogPost({ ...data, _id: blogId })).then(() => {
      toast.success('Post updated successfully!');
      navigate('/dashboard/blog');
    });
  } else {
    // Add a new post
    dispatch(addBlogPost(data)).then(() => {
      toast.success('Post added successfully!');
      navigate('/dashboard/blog');
    });
  }
  };

  useEffect(() =>{
    console.log(blogId);
    if(blogId){
      dispatch(fetchBlogPostById(blogId)).then((value) => {
        console.log('dispatch value =', value, post);
        reset({
          title: value.payload?.title,
          // content: value.payload?.content,
          slug: value.payload?.slug
        });
        setEditorContent(value.payload?.content);
      })
    } else {
      dispatch(resetPost());
    }
  }, []);
  
  const handleEditorChange = (content, editor) => {
    // setEditorContent(content);
  };

  return (
    <div className="container">
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
        <Typography variant='h5'>{blogId ? 'Edit' : 'Add'} Post</Typography>
        </Grid>
        <Grid item xs={12}>
        
          <TextField
            {...register('title', { required: 'Title is required' })}
            label="Title"
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            {...register('slug', { required: 'Slug is required' })}
            label="Slug"
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        
        <Grid item xs={12}>
          {/* <TextField
            {...register('content', { required: 'Content is required' })}
            label="Content"
            fullWidth
            InputLabelProps={{ shrink: true }}
          /> */}
          <Editor
        initialValue={editorContent}
        onChange={(e) => setEditorContent(e.target.value)}
        // {...register('content', { required: 'Content is required' })}
        apiKey='znclnvt4x9ddccsw1xtl8ajjig8zvkx6bntl5cesj11tod6f'
        onInit={(evt, editor) => {editorRef.current = editor}}
        onEditorChange={handleEditorChange}
        init={{
          height: 500,
          menubar: false,
          plugins: [
            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
            'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
          ],
          toolbar: 'undo redo | blocks | ' +
            'bold italic forecolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat | help',
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
        }}
      />
        </Grid>

        <Grid item xs={12}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            // onClick={() => handleSubmit(handleFormSubmit())}
          >
            {blogId ? 'Update Post' : 'Add Post'}
          </Button>
        </Grid>
      </Grid>
    </form>
    </div>
  );
}

export default AddBlog;
