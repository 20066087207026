import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import * as api from '../../../api';

export default function AddShopOwner() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [villages, setVillages] = useState([]);
  const { shopOwnerId } = useParams();
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    setValue,
  } = useForm();

  useEffect(() => {
    fetchVillages();
    if (shopOwnerId) {
      fetchShopOwnerData();
    }
  }, [shopOwnerId]);

  const fetchVillages = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/villages`); // Replace with your API endpoint
      setVillages(response?.data?.data?.villages);
      console.log(villages);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
    setLoading(false);
  };

  const fetchShopOwnerData = async () => {
    try {
      const response = await api.get(`/api/users/${shopOwnerId}`);
      const userData = response.data?.data;
      // Use setValue to set form field values instead of useState
      setValue('firstName', userData.firstName);
      setValue('lastName', userData.lastName);
      setValue('email', userData.email);
      setValue('mobile', userData.mobile);
      setValue('villageId', userData.villageId);
      setValue('status', userData.status);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const onSubmit = async (data) => {
    try {
      let response;
      if (shopOwnerId) {
        // Send a put request to update the existing user data
        data._id = shopOwnerId;
        response = await api.put(`/api/users`, data);
      } else {
        // Send a post request to add a new user
        response = await api.post('/api/users/addShopOwner', data);
      }
      if (response.data.status === 200) {
        navigate('/dashboard/shop-owners');
        const successMessage = shopOwnerId ? 'User updated successfully' : 'User added successfully';
        toast(successMessage);
      } else {
        // Handle error response
        toast(response.data?.message);
        console.error('Error saving user:', response.data.message);
      }
    } catch (error) {
      // Handle error
      toast(error.response?.data?.message);
      console.error('Error saving user:', error);
    }
  };

  return (
    <>
      <h4>Add Shop Owner</h4>
      <form onSubmit={handleSubmit(onSubmit)} className="form">
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="firstName">First Name:</label>
              <input
                id="firstName"
                name="firstName"
                type="text"
                {...register('firstName', { required: 'First name is required' })}
                className="form-control"
              />
              {isDirty && errors.firstName && <span style={{ color: 'red' }}>{errors.firstName.message}</span>}
            </div>

            <div className="form-group">
              <label htmlFor="lastName">Last Name:</label>
              <input
                id="lastName"
                name="lastName"
                type="text"
                {...register('lastName', { required: 'Last name is required' })}
                className="form-control"
              />
              {isDirty && errors.lastName && <span style={{ color: 'red' }}>{errors.lastName.message}</span>}
            </div>

            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input
                id="email"
                name="email"
                type="email"
                {...register('email', {
                  required: 'Email is required',
                  pattern: {
                    value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/i,
                    message: 'Invalid email format',
                  },
                })}
                className="form-control"
              />
              {isDirty && errors.email && <span style={{ color: 'red' }}>{errors.email.message}</span>}
            </div>

            <div className="form-group">
              <label htmlFor="mobile">Mobile:</label>
              <input
                id="mobile"
                name="mobile"
                type="tel"
                {...register('mobile', {
                  required: 'Mobile number is required',
                  pattern: {
                    value: /^\d{10}$/,
                    message: 'Mobile number must be 10 digits',
                  },
                })}
                className="form-control"
              />
              {isDirty && errors.mobile && <span style={{ color: 'red' }}>{errors.mobile.message}</span>}
            </div>

            <div className="form-group">
              <label htmlFor="status">Status:</label>
              <select
                id="status"
                name="status"
                {...register('status', { required: 'Status is required' })}
                className="form-control"
              >
                <option value="ACTIVE">Active</option>
                <option value="INACTIVE">Active</option>
              </select>
              {isDirty && errors.status && <span style={{ color: 'red' }}>{errors.status.message}</span>}
            </div>

            {villages && villages.length > 0 ? (
              <div className="form-group">
                <label htmlFor="villageId">Village:</label>
                <select
                  id="villageId"
                  name="villageId"
                  {...register('villageId', { required: 'Village is required' })}
                  className="form-control"
                >
                  <option value="">Select</option>
                  {villages.map((village) => (
                    <option key={village._id} value={village._id}>
                      {village.name}
                    </option>
                  ))}
                </select>
                {isDirty && errors.villageId && <span style={{ color: 'red' }}>{errors.villageId.message}</span>}
              </div>
            ) : (
              <div>Loading villages...</div>
            )}
          </div>
        </div>

        <div className="mt-2">
          <button type="submit" className="btn btn-primary">
            {shopOwnerId ? 'Update' : 'Add'}
          </button>
        </div>
      </form>
    </>
  );
}
