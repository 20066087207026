import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TextField, Button, Box, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import * as api from '../../../api';

export default function AddCrop() {
  const navigate = useNavigate();
  const { cropId } = useParams();
  const [name, setName] = useState('');
  const [code, setCode] = useState('');
  const [stages, setStages] = useState([{ 
    name: '',
    description: '', 
    fieldPreparationDays: 0,
    fieldPreparationDescription: '',
    nutrientDays: 0,
    nutrientDescription: '',
    weedDays: 0,
    weedDescription: '',
    insectDays: 0,
    insectDescription: '',
    diseaseDays: 0,
    diseaseDescription: '',
    noteDays: 0, 
    noteDescription: '',
  }]);

  useEffect(() => {
    if (cropId) {
      // Fetch the crop data using the cropId and populate the form fields
      fetchCropData();
    }
  }, [cropId]);

  const fetchCropData = async () => {
    try {
      const response = await api.get(`/api/crops/${cropId}`); // Replace with your API endpoint to fetch crop data
      const cropData = response.data?.data;
      setName(cropData.name);
      setCode(cropData.code);
      setStages(cropData.stages);
    } catch (error) {
      toast.error(error.response.data.message);
      console.error('Error fetching crop data:', error);
    }
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleCodeChange = (event) => {
    setCode(event.target.value);
  };

  const handleStageChange = (index, field, value) => {
    setStages((prevStages) => {
      const updatedStages = [...prevStages];
      updatedStages[index] = { ...updatedStages[index], [field]: value };
      return updatedStages;
    });
  };

  const handleAddStage = () => {
    setStages((prevStages) => [...prevStages, {
      name: '',
    description: '', 
    fieldPreparationDays: 0,
    fieldPreparationDescription: '',
    nutrientDays: 0,
    nutrientDescription: '',
    weedDays: 0,
    weedDescription: '',
    insectDays: 0,
    insectDescription: '',
    diseaseDays: 0,
    diseaseDescription: '',
    noteDays: 0, 
    noteDescription: '',
     }]);
  };

  const handleRemoveStage = (index) => {
    setStages((prevStages) => prevStages.filter((_, i) => i !== index));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const newCrop = {
      name,
      code,
      stages: stages.map((stage) => ({
        ...stage,
        fieldPreparationDescription: stage.fieldPreparationDescription,
        nutrientDescription: stage.nutrientDescription,
        weedDescription: stage.weedDescription,
        insectDescription: stage.insectDescription,
        diseaseDescription: stage.diseaseDescription,
        noteDescription: stage.noteDescription,
      })),
    };

    try {
      let response;
      if (cropId) {
        // Send a put request to update the existing crop data
        response = await api.put(`/api/crops/${cropId}`, newCrop); // Replace with your API endpoint to update the crop
      } else {
        // Send a post request to add a new crop
        response = await api.post('/api/crops', newCrop); // Replace with your API endpoint to save the crop
      }

      if (response.data.status === 200) {
        setName('');
        setCode('');
        setStages([{ 
          name: '',
    description: '', 
    fieldPreparationDays: 0,
    fieldPreparationDescription: '',
    nutrientDays: 0,
    nutrientDescription: '',
    weedDays: 0,
    weedDescription: '',
    insectDays: 0,
    insectDescription: '',
    diseaseDays: 0,
    diseaseDescription: '',
    noteDays: 0, 
    noteDescription: '',
         }]);
        navigate('/dashboard/crops');
        const successMessage = cropId ? 'Crop updated successfully' : 'Crop added successfully';
        toast.success(successMessage);
      } else {
        // Handle error response
        console.error('Error saving crop:', response.data.message);
      }
    } catch (error) {
      // Handle error
      console.error('Error saving crop:', error);
    }
  };

  return (
    <>
      <div className="container">
      <Typography variant="h4">Add Crop</Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Crop Name"
          value={name}
          onChange={handleNameChange}
          fullWidth
          required
          size="small"
          sx={{ my: 2 }}
        />

        <TextField
          label="Crop Code"
          value={code}
          onChange={handleCodeChange}
          fullWidth
          required
          size="small"
          sx={{ my: 2 }}
        />

        <h5>Stages</h5>
        {stages.map((stage, index) => (
          <div key={index} className='row alert alert-light'>
            <Box sx={{ marginBottom: '1rem' }} className='col-md-12' >
              <TextField
                label={`Stage Name (${index + 1})`}
                value={stage.name}
                onChange={(event) => handleStageChange(index, 'name', event.target.value)}
                fullWidth
                required
                size="small"
              />
            </Box>

            

            <Box sx={{ marginBottom: '1rem' }} className='col-md-2' >
              <TextField
                type="text"
                label={`Field Preparation Days (${index + 1})`}
                value={stage.fieldPreparationDays}
                onChange={(event) => handleStageChange(index, 'fieldPreparationDays', event.target.value)}
                fullWidth
                required
                size="small"
              />
            </Box>

            <Box sx={{ marginBottom: '1rem' }} className='col-md-10' >
              {/* <TextField
                label={`Field Preparation Description (${index + 1})`}
                value={stage.fieldPreparationDescription}
                onChange={(event) => handleStageChange(index, 'fieldPreparationDescription', event.target.value)}
                fullWidth
                required
                size="small"
              /> */}
              <ReactQuill
  value={stage.fieldPreparationDescription}
  onChange={(content) => handleStageChange(index, 'fieldPreparationDescription', content)}
/>
            </Box>

            <Box sx={{ marginBottom: '1rem' }} className='col-md-2' >
              <TextField
                type="text"
                label={`Nutrient Days (${index + 1})`}
                value={stage.nutrientDays}
                onChange={(event) => handleStageChange(index, 'nutrientDays', event.target.value)}
                fullWidth
                required
                size="small"
              />
            </Box>

            <Box sx={{ marginBottom: '1rem' }} className='col-md-10' >
              {/* <TextField
                label={`Nutrient Description (${index + 1})`}
                value={stage.nutrientDescription}
                onChange={(event) => handleStageChange(index, 'nutrientDescription', event.target.value)}
                fullWidth
                required
                size="small"
              /> */}
            <ReactQuill
              value={stage.nutrientDescription}
              onChange={(content) => handleStageChange(index, 'nutrientDescription', content)}
            />
            </Box>

            <Box sx={{ marginBottom: '1rem' }} className='col-md-2' >
              <TextField
                type="text"
                label={`Weed Days (${index + 1})`}
                value={stage.weedDays}
                onChange={(event) => handleStageChange(index, 'weedDays', event.target.value)}
                fullWidth
                required
                size="small"
              />
            </Box>

            <Box sx={{ marginBottom: '1rem' }} className='col-md-10' >
              {/* <TextField
                label={`Weed Description (${index + 1})`}
                value={stage.weedDescription}
                onChange={(event) => handleStageChange(index, 'weedDescription', event.target.value)}
                fullWidth
                required
                size="small"
              /> */}
              <ReactQuill
              value={stage.weedDescription}
              onChange={(content) => handleStageChange(index, 'weedDescription', content)}
            />
            </Box>

            <Box sx={{ marginBottom: '1rem' }} className='col-md-2' >
              <TextField
                type="text"
                label={`Insect Days (${index + 1})`}
                value={stage.insectDays}
                onChange={(event) => handleStageChange(index, 'insectDays', event.target.value)}
                fullWidth
                required
                size="small"
              />
            </Box>

            <Box sx={{ marginBottom: '1rem' }} className='col-md-10' >
              {/* <TextField
                label={`Insect Description (${index + 1})`}
                value={stage.insectDescription}
                onChange={(event) => handleStageChange(index, 'insectDescription', event.target.value)}
                fullWidth
                required
                size="small"
              /> */}
              <ReactQuill
              value={stage.insectDescription}
              onChange={(content) => handleStageChange(index, 'insectDescription', content)}
            />
            </Box>

            <Box sx={{ marginBottom: '1rem' }} className='col-md-2' >
              <TextField
                type="text"
                label={`Disease Days (${index + 1})`}
                value={stage.diseaseDays}
                onChange={(event) => handleStageChange(index, 'diseaseDays', event.target.value)}
                fullWidth
                required
                size="small"
              />
            </Box>

            <Box sx={{ marginBottom: '1rem' }} className='col-md-10' >
              {/* <TextField
                label={`Disease Description (${index + 1})`}
                value={stage.diseaseDescription}
                onChange={(event) => handleStageChange(index, 'diseaseDescription', event.target.value)}
                fullWidth
                required
                size="small"
              /> */}
              <ReactQuill
              value={stage.diseaseDescription}
              onChange={(content) => handleStageChange(index, 'diseaseDescription', content)}
            />
            </Box>

            <Box sx={{ marginBottom: '1rem' }} className='col-md-2' >
              <TextField
                type="text"
                label={`Note Days (${index + 1})`}
                value={stage.noteDays}
                onChange={(event) => handleStageChange(index, 'noteDays', event.target.value)}
                fullWidth
                required
                size="small"
              />
            </Box>

            <Box sx={{ marginBottom: '1rem' }} className='col-md-10' >
              {/* <TextField
                label={`Note Description (${index + 1})`}
                value={stage.noteDescription}
                onChange={(event) => handleStageChange(index, 'noteDescription', event.target.value)}
                fullWidth
                required
                size="small"
              /> */}
              <ReactQuill
              value={stage.noteDescription}
              onChange={(content) => handleStageChange(index, 'noteDescription', content)}
            />
            </Box>

            <div className="col-md-3">
              <Button variant="outlined" onClick={() => handleRemoveStage(index)} size="small">
                Remove Stage
              </Button>
            </div>

          </div>
        ))}

        <Button variant="outlined" onClick={handleAddStage} sx={{ my: 2 }} size="small">
          Add Stage
        </Button>

        <br />

        <Button type="submit" variant="contained" size="small">
          {cropId ? 'Update Crop' : 'Add Crop'}
        </Button>
      </form>
      </div>
    </>
  );
}
